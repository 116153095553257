<template>
  <div style='margin: 0pt; height: 100%; width:100%;'>
 
  <div style='width: 100%;' :style='getAppStyle()'>
     <div style='width: 100%;'>
       <div class="xmodal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' :style='getAppStyle()'>
            	Ad-Grid for {{placement.name}} 
            	
                </div>
               

            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div>
          <slot name="body">
            <div style='float: top; width: 100%;' >
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
			        <!-- FILTERS -->    
				    <div class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
			        </div>
			        
				    <span class='dontWrap smallText bold'>Channel/Media:</span>
					<GSelect :options="allMedia" v-model="selectedMedia" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Pricegroups:</span>
					<GSelect :options="allPG" v-model="selectedPG" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Timeslots:</span>
					<GSelect :options="allTimeslots" v-model="selectedTimeslots" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Grids/Programs:</span>
					<GSelect :options="allGrids" v-model="selectedGrids" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Program Categories:</span>
					<GSelect :options="allCats" v-model="selectedCats" :multiple="true" @input="doFilterGrid()">  
				    </GSelect>
				    <button @click='clearFilter()' :style="getAppStyle()" class='button myButton'>Remove filter</button>


				    <br/>
				    <span class='dontWrap smallText bold'>Days displayed:</span>
					<vue-slider style='z-index: 0;' :min="10" :max="dates.length" v-model="limitDays"></vue-slider>
					<br/>
					
					<!-- INFO -->    
					<div v-show="placement.target" class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>INFO</b></span>
			        </div>
			        <div v-if="targetType.spots && placement.target" class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
				        <div style='float: left; display: flex; width: 60%;' class='smallText bold'>Spots: {{ Math.max(0,markedSpots +spotsPlacementTotal-spotsPlacementTotalFree)}} / {{placement.targetTotal}} <span v-if="spotsPlacementTotalFree" style='padding-left: 5pt;'>(+{{ spotsPlacementTotalFree }})</span></div>
				        <div style='display: flex; padding-left: 10pt !important; '>
				        <GProgress :percentage="getPercent(markedSpots+spotsPlacementTotal-spotsPlacementTotalFree, {}, placement.targetTotal )"/>
		            </div>
		            </div>
		            <div v-else class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
		              <div style='float: left; display: flex; width: 60%;' class='smallText bold'>Spots: {{  Math.max(0, markedSpots +spotsPlacementTotal-spotsPlacementTotalFree)}} <span v-if="spotsPlacementTotalFree" style='padding-left: 5pt;'>(+{{ spotsPlacementTotalFree }})</span></div>
				        
		            </div>
		            
		            <div v-if="targetType.grp && placement.target" class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
				        <div style='float: left; display: flex; width: 60%;' class='smallText bold'>GRP: {{ Math.round(100*(markedGRP +grpPlacementTotal))/100}} / {{placement.targetTotal}}</div>
				        <div style='display: flex; padding-left: 10pt !important; '>
				        <GProgress :percentage="getPercent(markedGRP +grpPlacementTotal, {}, placement.targetTotal )"/>
			            </div>
			 
			            
				    </div>	  
				    <div v-else-if="placement.targetgroupId" class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
			        <div style='float: left; display: flex; width: 50%;' class='smallText bold'>GRP: {{fmt(markedGRP +grpPlacementTotal)}}</div>
			        
				    </div>  
				    
				    <div v-if="placement.budget" class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
			        <div v-if="placement.payPercentage==100" style='float: left; display: flex; width: 60%;' class='smallText bold'>Budget: {{format(budgetPlacementTotal)}} / {{format(placement.budget)}}</div>
			        <div v-else style='float: left; display: block; width: 60%;' class='smallText bold'>
						Budget: {{format(budgetPlacementTotal/(0.01*placement.payPercentage))}} ({{format(budgetPlacementTotal)}}={{ placement.payPercentage}}%)
						/ {{format(placement.budget)}} 
					</div>
			        
			        <div style='display: flex; padding-left: 10pt !important; '>
			        <GProgress :percentage="getPercent(budgetPlacementTotal/(0.01*placement.payPercentage), {}, placement.budget )"/>
			        </div>
				    </div>
				    
			        <div class='dontWrap smallText' style='display: block; position: relative; float: left; width: 100% !important; margin: 5pt !important;'>
			        <div style='float: left; display: flex; width: 60%;' class='smallText bold'>Ratecard: {{format(rateCardPlacementTotal)}}</div>
			        
				    </div>	  
				    	      
			
				   
				    <!-- ACTIONS -->    
					<div v-show="markedSpots" style='margin-top: 16pt;' class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>ACTIONS</b></span>
			        </div>
					<div v-show="markedSpots">
			        	<button @click='bookMarked($event)' :style="getAppStyle()" class='button myButton'>Book {{markedSpots}} Spots</button>
			        	<button @click='cancelMarked()'     :style="getAppStyle()" class='button myButton'>Cancel {{markedSpots}} Breaks</button>
			        </div>
			      
			           
			       
			    </div> <!-- CONTROLS -->
    		</div>
    		</div>
    
    		<!-- MAIN -->
            <div class='transition'  style='float: left; width: 57%; height: calc(75vH); overflow-y: scroll; '> 
            
				<div v-for="(channel,idx) in mediaSet" :key="'ch_'+idx" style='width: 100%;'>
            
		           <b>{{channels[channel].name}}</b>
		           <div style='float: top; width: 100%; ' >
		           <div style='float: left; display: flex;'>
			            <div>
						    <div style='float: top left; overflow-y: hidden;' class='BMHeadActions'
						         @click="markAll(channel)" title='mark *ALL* visible breaks'>
						        <div class="BMday transition" >
							        <div class='BMheader BMheadLine BMHeadActions' :style="getStyle2('channel')">
							        <div style='height: 100%; display : flex; align-items : center;'>
								      Time
								    </div>
								    </div>
								</div>
								<div class="BMday transition">
							        <div class='BMheader BMheadLine BMHeadActions' :style="getStyle2('program')">
							          <div style='height: 100%; display : flex; align-items : center;'>
								         Program
								      </div>
								    </div>
								</div>
								<div class="BMday transition">
							        <div class='BMheader BMheadLine BMHeadActions' :style="getStyle2('break')">
							          <div style='height: 100%; display : flex; align-items : center;'>
								        Break
								      </div>
								    </div>
								</div>
						   </div>
						   
						   <div style='float: top left;  overflow-y: hidden;'>
						   		<div v-for="(slot,idx0) in timeSlots[channel]" :key="'slot_'+idx0">
							        <div class="BMday transition">
								        <div class='BMheader BMtime' :style="getStyle('channel', channel, slot)">
									    {{printTime2(slot)}}
									    <div v-show="channelDiffs[ channel]" class="BMuserTime">
									    {{printTime2(slot+channelDiffs[ channel])}}
									    </div>
									    </div>
									     
									</div>
									<div style='display: table-cell;'>
									<div class='BMheader' :style="getStyle('full', channel, slot)">
										<div style='display: block;' class="day transition" v-for="(iGrid,idx1) in gridData[channel][slot]" :key="'igrd_'+idx0+'_'+idx1">
											<div class="BMday transition">
										        <div title='mark all visible breaks for this program' 
										             @click="markGridAll(channel, iGrid)" class='BMheader BMprogram' :style="getStyle('program','','',iGrid)">
											    	<div style='position: relative; top:0px; height: 20pt; width: 120pt;'>
											    	<mdicon :title="'Rerun'" v-if="iGrid.detail.firstRunId" :width="14" :height="14" name="repeat" />
											    	{{iGrid.detail.name}}<br/>PG-{{iGrid.detail.pricegroup}}
											    	<span v-if="iGrid.detail.starttime!=slot" v-tooltip="iGrid.slotDetail?iGrid.slotDetail.name:'breakgrid'" style='padding-left: 5pt; color: #666;'>
											    	({{printTime2(iGrid.detail.starttime)}})</span>
											    	</div>
											    </div>
											</div>
											<div style='display: table-cell;'>
											    <div class='BMheaderInv BMinfo'  :style="getStyle('break')+';color: transparent;width: 100%;'">xx</div>
											    <div class='BMinfo' :style="getStyle('break')">&nbsp;
											    </div>
												<div class='BMheader' :style="getStyle('full', '', '', iGrid)">
													<div style='display: block;' class="BMday transition" v-for="(adspace,idx2) in iGrid.adspace" :key="'as_'+idx0+'_'+idx1+'_'+idx2">
														<div style='display: block;' class="BMday transition" v-for="(bType,idx3) in filter(adspace.breakTypes)" :key="'br_'+idx0+'_'+idx1+'_'+idx2+'_'+idx3" >
															<div title='mark all visible Breaks' @click="markRow(channel, iGrid, adspace, bType)" class="BMday transition">
															    <div class='BMbreak BMheader' :style="getStyle('break')">
															       {{printAdSpace(bType, adspace)}}
															    </div>	
														    </div>
														    
														</div>    
													</div>
										
												</div>
												</div>
										    
										</div>
							
									</div>
									</div>
							        
								 </div>
						   </div>	  
					   </div>  
				   </div>
		        </div>
			  
		           <div style='float: left; display: flex;'>
			       <div :style='getStyleForDayTable()'>
			            <div>
						    <div style='float: top left; overflow-y: hidden;'>
		
							    <div class="BMday transition" v-for="(day,idx) in limit(dates)" :key="'d_'+idx">
								    <div v-show="weekdaySet(inputWeekdays,day)" class='BMheaderDay BMheadLine' :style="getStyle('day')">
								    {{weekdayLabel[day]}}{{ day}}
								    </div>	
							    </div>
						   </div>
						   <div style='float: top left; overflow-y: hidden;'>
		
							    <div class="BMday transition" v-for="(day,idx) in limit(dates)" :key="'i_'+idx" >
								    <div v-show="weekdaySet(inputWeekdays,day)" class='BMheaderDay BMbreak' title='mark all visible Breaks on this day' @click='markAllDate(channel, day)' :style="getStyle('day')">
								    {{getInfo(channels[channel], day, spots)}}
								    </div>	
							    </div>
						   </div>
						   <div style='float: top left;  overflow-y: hidden;'>
						   		<div v-for="(slot,idx0) in timeSlots[channel]" :key="'slot_'+idx0">
							        
									<div style='display: table-cell;'>
									<div class='BMheader' :style="getStyle('full', channel, slot)">
										<div style='display: block;' class="BMday transition" v-for="(iGrid,idx1) in gridData[channel][slot]" :key="'grd_'+idx0+'_'+idx1">
										    <div style='float: top left; overflow-y: hidden;'>
		
											    <div class="BMday transition" v-for="(day,idx2) in limit(dates)" :key="'i1_'+idx0+'_'+idx1+'_'+idx2">
												    <div v-show="weekdaySet(inputWeekdays,day)" class='BMheaderDay BMheadLine transition' :style="getStyle('day')">
												    {{ day}}
												    </div>	
											    </div>
										   </div>
											<div style='float: top left; overflow-y: hidden;'>
											    <div v-show="weekdaySet(inputWeekdays,day)" class="BMday transition" v-for="(day,idx2) in limit(dates)" :key="'i2_'+idx0+'_'+idx1+'_'+idx2">
												    <div class='BMheaderDay BMbreak' title='mark all visible Breaks in this program on this day' 
												    :style="getStyle('day', channel, null, iGrid, day)" 
												    @click='markGridDate(channel, iGrid, day)'>
												    {{getInfo(channels[channel], day, spotsDay, iGrid)}}
												    </div>	
											    </div>
										    </div>
											<div style='display: table-cell;'>
												<div class='BMheader' :style="getStyle('full', '', '', iGrid)">
													<div style='display: block;' class="day transition" v-for="(adspace,idx2) in iGrid.adspace" :key="'as2_'+idx0+'_'+idx1+'_'+idx2">
														<div style='display: block;' class="day transition" v-for="(bType,idx3) in filter(adspace.breakTypes)" :key="'br2_'+idx0+'_'+idx1+'_'+idx2+'_'+idx3">
														    <div class="BMday transition" v-for="(day,idx4) in limit(dates).filter(d=>weekdaySet(inputWeekdays,d))" :key="'br3_'+idx0+'_'+idx1+'_'+idx2+'_'+idx3+'_'+idx4">
															    <div  v-for="(asx,asxi) in as( iGrid, adspace, bType, day)" :key="'b'+asxi"> <!-- Single loop-->
																	<div  v-for="(key,keyi) in getKeyArr(channel, iGrid, asx, bType, day)" :key="'k'+keyi"> <!-- Single loop-->	
																		
																		<div v-if="asx && hasAdBreak(channel, iGrid, adspace, bType, day, 'disp')" 
																	         class='BMadbreak'
																	         @click='mark(channel, iGrid, asx, bType, day)' 
																	         @drop="onDrop($event, key)"
																	         @dragover.prevent
					  														 @dragenter.prevent
					  														 @contextmenu="nodeRClicked($event, key, channel, day)"
																	         :style="getStyle4Break(channel, iGrid, day, asx, bType)"
																	         >
																	         <div :title="key"  style='display: inline-block;' class='todrag' draggable @dragstart="startDrag($event, key)">
																				<!-- {{ iGrid.clashes }} -->
																				 
																		         <BookingGridBreak :clashes="iGrid.clashes.includes( key)" :options=options :breakInfo="getInfoObj(channels[channel], day, spotsBreak, iGrid, asx, adspace, key, bType, targetGroup)"/>
																				
																				 <div class='BMadbreak2'>
																				 	<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" :fill="markedColor( channel, iGrid, asx, bType, day, markedSpots)" class="bi bi-check" viewBox="0 0 11 11">
																					  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
																					</svg>
																				 </div>
																	        </div>
																	    </div>	
																	    <div v-else :class="'BMadbreak '+(hasExcl(channel, iGrid, adspace, bType, day)?'BMnoadbreakExcl':'BMnoadbreak')" draggable @dragstart="startDrag($event, key)">
																	    	<div class='BMadbreak1' >
																		           {{getInfo(channels[channel], day, spotsDay, iGrid, adspace, bType)}}
																				 </div>
																				 <div class='BMadbreak2'>
																				 	<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="transparent" class="bi bi-check" viewBox="0 0 11 11">
																					  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
																					</svg>
			
																				 </div>
																	    </div>	
																	</div>
														    	</div>
														    </div>
														</div>    
														</div>
											
													</div>
													</div>
											    
											</div>
								
										</div>
									</div>
							        
								 </div>
						   </div>	  
					   </div>  
				   </div> 
				   </div>
				   
			    </div>
			
			</div> <!-- mainContent -->
			
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  
	  
	 </div> <!-- modal-wrapper -->  
   
    <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="openBreak($event, contextData.key)">
          Open Break {{contextData.key}}
        </ContextMenuItem>
        <ContextMenuItem  @clicked="openDetailPlan( contextData.channel, contextData.day)">
          Open RunDown Screen {{contextData.day}}
        </ContextMenuItem>
       
       </template>
    </ContextMenu>
    <BreakView ref='breakView' :reference=selectedRef :time="new Date().getTime()" 
        :options=options
        @saveUser=saveUser
        @deleteSpot="deleteSpot"
        @setTypeForSpots="setTypeForSpots"
        @close="closeBreakView()">
    </BreakView>
    

   
 </div>
</template>

<script>
import GFWTreeView from '@/components/GFWTreeView';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InputTime from '@/components/inputElements/InputTime2';
//import SimpleSpotList from '@/components/SimpleSpotList';
//import BreakView from '@/components/BreakView';
import GProgress from '@/components/misc/GProgress';
import BreakView from '@/components/BreakView';
import BookingGridBreak from '@/components/booking/BookingGridBreak';
import GSelect from '@/components/misc/GSelect';

import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import {HTTP, fwAPI, invAPI, bngAPI, setReload, formatNumber} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import {fmtTimePart, printTimeHHMM, printTime2 } from '@/basicTimeFN.js';
import { formatPercent, format } from '@/Numbers.js';
import { getDataByName}  from '@/utils.js';
import { getRawKey, getRawKeyById } from '@/bookMan.js';
import { setGoBack } from '@/breadCrumb.js';
import { openView } from '@/utils.js';
var numeral = require('numeral');
var timers = [];
export default {
  name: 'GFW BookingGrid',
  components : {
    ContextMenu, ContextMenuItem, BreakView, GProgress, GSelect
	, BookingGridBreak
  },
  props: {
  	spotsPlacementTotal: {type: Number, defaultValue: 0}, 
  	spotsPlacementTotalFree: {type: Number, defaultValue: 0}, 
  	budgetPlacementTotal: {type: Number, defaultValue: 0}, 
  	grpPlacementTotal: {type: Number, defaultValue: 0}, 
  	rateCardPlacementTotal: {type: Number, defaultValue: 0}, 
  	targetType: Object,
    grid: Array,
    options: Object,
    targetGroup: Object,
    markBreaks: Array,
    spotsDay: Array,
    spotsGrid: Array,
    spotsUnplacedBreak: Array,
    spotsBreak: Array,
    consumedGrid: Array,
    consumedBreak: Array,
    spotsBreakTotal: Array,
    invalidSpots: Array,
    user: Object,
    invalidSpotsChanged: {type: Number, defaultValue: 0},
    allBookingStats: Array,
    placementInfo: Object,
    fromDate: String,
    untilDate: String,
    inputWeekdays: {type: Number, defaultValue: 127},
    inputBreakTypeIds: Array,
    time: Number
  },
  data () {
    return {
      placement: {},
      placementFrom: null,
      dayHasBreak: null,
      placementTo: null,
      openThisModule: String,
      inventory: {},
      gridData: [],
      mediaSet: {},
      channels: {},
      channelDiffs: [],
      timeSlots: {},
      duration: {},
      
      durationData: [],
      breakTypeData: [],
      plcmBreakTypes: [],
      
      showEditor: false,
      entityMap: {},
      spotListTime: 0,
      mapping: [],
      metadata: {},
      dataId: 0,
      //
      allMedia: [],
      selectedMedia: [],
      allTimeslots: [],
      selectedTimeslots: [],
      allPG: [],
      selectedPG: [],
      allGrids: [],
      selectedGrids: [],
      allCats: [],
      selectedCats: [],
      //
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      widths: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      dates: [],
      isoDates: [],
      fontSize: {},
      limitDays: 60,
      spotsPlacement: [],
      
      spots: 0,
      markedSpots: 0,
      markedGRP: 0,
      shown: false,
      showBreakView: false,
      
      selectedRef: "",
      selectedGrid: {},
      loader: {},
      format,
      getAppStyle,
      getBGStyle2,
      loadingActive: false
    }
  },
  beforeUpdate: function () {
  	this.getOpenThisModule();
  },

  methods: {
    getOpenThisModule() 
    {
    	this.openThisModule = this.$route.query.toOpen;
    	return this.openThisModule;
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex;"
    },

    limit(dates)
     {
     	let maxLen = this.limitDays;
        if ( dates.length > maxLen )
        {
        	return dates.slice().splice( 0, maxLen );
        }
        return dates;
     },
     getPercent( spots, targetType, target)
    {
    	return target==0? 100: Math.round(10000*spots/target)/100;
    }, 
    getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
    fmt( val)
    {
       return formatNumber( val );
    },
    openDetailPlan(stationId, day)
    {
    	let isoDay = this.isoDates[day];
    	//alert( day +"-->"+this.$router.currentRoute.path + "/"+this.$route.params)
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+stationId+'&day='+ isoDay);
    },
    adSpace(iGrid)
    {
    	
    	if ( iGrid.replacements )
    	{
    		let r = iGrid.replacements.find( p=>p.date !== null);
    		if ( r)
    		{
    			//alert( JSON.stringify( iGrid.adspace))
    			return iGrid.adspace.concat( iGrid.adspace);
    		}
    	}
    	return iGrid.adspace;
    },
    saveUser( user)
    {
    	this.$emit("saveUser", user);
    },
    deleteSpot( toDelete)
    {
    	this.$emit("deleteSpot", toDelete);
    },
    setTypeForSpots( mySpots, type)
    {
    	this.$emit("setTypeForSpots", mySpots, type);
    },
    closeDetails() {
       this.treeHeight=90.0;
       this.placements = [];
       this.gridValid = [];
       this.inventory={};
       this.placement={};
       this.selectedGrid={}
       this.showPlacementGrid = false;
       this.showPlacementSpots = false;
    },
    
    getWidth() 
    {
    	let max = window.innerWidth;
    	
    	let avail = (max * 0.55) - 210;

    	return avail;
    },
    getHeight() 
    {
    	let max = window.innerHeight;
    	
    	let avail = (max)-120;

    	return avail;
    },
    calcHeight()
    {
    	let mh = window.innerHeight;
    	return "height: " + (mh - (mh*this.treeHheight)*.01)+"px;"
    },
    
    printAdSpace( breakType, adSpace)
    {
    	if ( adSpace.label )
    	{
    		return adSpace.label;
    	}
    	if ( adSpace.no === 0 )
    	{
    		return breakType.shortname;
    	}
    	if ( adSpace.no === 999 )
    	{
    		return breakType.shortname;
    	}
    	return breakType.shortname+"-"+adSpace.no;
    },
    startDrag (evt, source) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('fromBreak', source)
	},
	onDrop (evt, target) {
		const source = evt.dataTransfer.getData('fromBreak')
		if ( source )
		{
			var fromTo = new Array();
			fromTo.push( source);
			fromTo.push( target);
			this.$emit("bookMove", fromTo )
		}
		const spots = evt.dataTransfer.getData('spots')
		if ( spots )
		{
			const setToStatusId = evt.dataTransfer.getData('setToStatusId');
			fromTo = { spots: JSON.parse(spots), setToStatusId: setToStatusId?setToStatusId:0, line: target };
			this.$emit("moveSingleSpot", fromTo )
		}
		
	},
    nodeRClicked(event, key, channel, day)
    {
        event.preventDefault();
        this.$refs.menu.open(event, { key: key, channel: channel, day: day} )
    },
    openBreak( event, ref ) {
    	this.selectedRef = ref;
    	this.$refs.menu.close();
    	this.$refs.breakView.open(ref);
    },
    closeBreakView() {
    	this.$refs.breakView.close();
    },
    getMainStyle()
    {
    	return "padding: 10pt 10pt 10pt 10pt; float: left; width: 75%; height: "+this.getHeight()+"px; overflow-y: scroll;";
    },
    filter( breakTypes )
    {
    	var copy = new Array;
    	
    	for ( var bi in breakTypes )
    	{
    		let b = breakTypes[bi];
    		if ( this.breakTypeVisible( b.id))
    		{
    			copy.push(b);
    		}
    	}
    	return copy;
    }, 
    breakTypeVisible( typeId )
    {
    	return this.inputBreakTypeIds.includes(typeId);
    },
    selectedSpots(unselect)
    {
    	var spots = new Array()
    	for ( var x in this.marked )
    	{
    		if ( this.marked[x] )
    		{
    		    if ( unselect )
    		    {
    		    	this.marked[x] = false;
    		    }
    			spots.push( x );
    		}
    	}
    	return spots;
    },
    bookMarked()
    {
        var spots = this.selectedSpots(false);
    	var bookReq = {placement: this.placement, lines: spots};
    	//alert( JSON.stringify(spots));
    	this.$emit("bookMarked", bookReq )
    },
    cancelMarked()
    {
        var spots = this.selectedSpots(true);
    	this.$emit("cancelMarked", spots )
    },
    getBreakId( day, grid, adspace, weekdays )
    {
    	if ( adspace.label )
    	{
    		let wd = this.weekdays[day];
        	let wdBit = Math.round(Math.log(wd)/Math.log(2));
    		return grid.adspacePerWeekday[wdBit].find( p=>p.label===adspace.label).id;
    	}
    },
	getKeyArr(channel, grid, adspace, breaktype, day)
	{
		let x = this.getKey(channel, grid, adspace, breaktype, day);
		return [ x];
	},
    getKey(channel, grid, adspace, breaktype, day)
    {
		if ( adspace && adspace.id )
		{
			return getRawKeyById( adspace.id, this.isoDates[ day]);
		}
		if ( adspace && adspace.no )
		{
    		return getRawKey( channel, grid.detail.id, adspace.no, breaktype.id, this.isoDates[ day]);
		}
		return "-";
    },

    getDayDiff( d, start)
    {
    	let oneDay = 1000 * 60 * 60 * 24;
    	let day = Math.floor(d-start / oneDay);
    	return day;
    },
    hasExcl( channel, grid, adspace, breaktype, day)
    {
		let isoDay = this.isoDates[day];
    	
    	if ( grid.forbidden && grid.forbidden.includes( isoDay))
    	{
    		return true;
    	}
    	return false;
    },
    
	as( grid, adspace, breaktype, day)
	{
		 try
		 {
		let wd = this.weekdays[day];
		let wdBit = Math.round(Math.log(wd)/Math.log(2));
		let result = null;
		if ( !grid.adspacePerWeekday[wdBit])
		{
			return [{}];
		}
		if ( adspace.label )
		{
			result = grid.adspacePerWeekday[wdBit].find( p=>p.label===adspace.label);
		}
		else
		{
			result = grid.adspacePerWeekday[wdBit].find( p=>p.no===adspace.no && p.breakTypes.find(b=>b.id===breaktype.id) != null);
		}
		if ( result )
		{
			
			return [ result];
		}
		} catch (e)
		{
			console.log(e);
		}
		
		return [{}];
	},
    hasAdBreak( channel, grid, adspace, breaktype, day, from)
    {
		//console.log(channel +","+ grid+","+ adspace+","+ breaktype+","+ day+","+ from)
    	let wd = this.weekdays[day];
    	let isoDay = this.isoDates[day];
    	if ( grid.invalid && grid.invalid.includes( isoDay))
    	{
    		return false;
    	}
    	//
    	
    	
    	//console.log( JSON.stringify( grid.forbidden))
    	if ( grid.forbidden && grid.forbidden.includes( isoDay))
    	{
    		return false;
    	}
    	let d = new Date( isoDay);
    	let key = day+"."+grid.gridId+"."+grid.replacementForGridId;
    	//console.log( JSON.stringify( adspace));
    	if ( adspace && adspace.no)
    	{
    		let wdBit = Math.round(Math.log(wd)/Math.log(2));
    		//console.log( wdBit + "/" + JSON.stringify( grid.adspacePerWeekday));
    		if ( grid.adspacePerWeekday[wdBit])
	    	{
    			if ( adspace.label )
    			{
    				return grid.adspacePerWeekday[wdBit].find( p=>p.label===adspace.label) != null;
    			}
	    		return grid.adspacePerWeekday[wdBit].find( p=>p.no===adspace.no && p.breakTypes.find(b=>b.id===breaktype.id) != null) != null;
	    	}
    		//return true;
    	}
		//console.log( JSON.stringify( key));
		
		//let wd = this.weekdays[day];
		//console.log("weekdays: "+wd+"/"+(grid.detail.weekdays & wd)+"/"+grid.detail.weekdays)
		//console.log( JSON.stringify( this.dayHasBreak[key]));
    	if ( this.dayHasBreak[key] !== 1 )
    	{
	    	
	    	if ( grid.replaced )
	    	{
	    		//alert( JSON.stringify(grid))
	    		let r = grid.replaced.find( p=>p==isoDay);
	    		if ( r )
	    		{
					//console.log("replaced: "+r)
	    			this.dayHasBreak[key] = 1;
	    		}
	    	}
	    	if ( !(grid.detail.weekdays & wd) )
	    	{
				//console.log("weekdays: "+wd+"/"+(grid.detail.weekdays & wd)+"/"+grid.detail.weekdays)
	    		this.dayHasBreak[key] = 1;
	    	}
	    	
	    	
	    	if ( d < this.placementFrom)
	    	{
				//console.log("placementFrom: "+this.placementFrom)
	    		this.dayHasBreak[key] = 1;
	    		
	    	}
	    	
	    	if ( d > this.placementTo)
	    	{
				//console.log("placementTo: "+this.placementTo)
	    		this.dayHasBreak[key] = 1;	    		
	    	}
	    	
	    	//let pfrom = new Date( grid.detail.fromDate);
	    	//alert( pfrom+ " - " + (d < pfrom));
	    	if ( d < grid.detail.fromDate)
	    	{
				//console.log("grid.detail.fromDate: "+grid.detail.fromDate)
	    		this.dayHasBreak[key] = 1;
	    	}
	    	//let pTo = new Date( grid.detail.untilDate);
	    	if ( d > grid.detail.untilDate)
	    	{
				//console.log("grid.detail.untilDate: "+grid.detail.untilDate)
	    		this.dayHasBreak[key] = 1;
	    	}
	    	if ( !this.dayHasBreak[key] )
	    	{
	    		this.dayHasBreak[key] = 2;
    		}
    	}
    	//console.log("KEY: "+this.dayHasBreak[key])
    	if ( this.dayHasBreak[key] === 1 )
    	{
    		return false;
    	}

    	return true;
    	
    },
    markGridDate(channel, grid, day )
    {										
        for ( var n in grid.adspace) 
        {
        	let adspace = grid.adspace[ n];
        	for ( var bt in adspace.breakTypes) 
        	{	
        		let breaktype = adspace.breakTypes[ bt]
            
	        	if ( this.hasAdBreak(channel, grid, adspace, breaktype, day, 'mark'))
	        	{
	        		if ( this.breakTypeVisible( breaktype.id) )
	        		{
	        			this.mark( channel, grid, this.as( grid, adspace, breaktype, day)[0], breaktype, day);
	        		}
	        	}
	        }
        }
    },
    markAllDate(channel, day )
    {										
        for ( var slot in this.timeSlots[channel])
        {
        	var timeSlot = this.timeSlots[channel][slot];
        	
        	for ( var igrid in this.gridData[channel][timeSlot])
        	{
        		var grid = this.gridData[channel][timeSlot][igrid];

        		for ( var iadspace in grid.adspace)
        		{
        			var adspace = grid.adspace[iadspace];
        			let types = this.filter( adspace.breakTypes);
        			for ( var ibType in types)
        			{
        				var bType = types[ibType];
        				if ( this.hasAdBreak(channel, grid, adspace, bType, day, "markAll"))
			        	{
			        		if ( this.weekdaySet( this.inputWeekdays,day) )
			        		{
			        			if ( this.breakTypeVisible( bType.id) )
	        					{
			        				this.mark( channel, grid, this.as( grid, adspace, bType, day)[0], bType, day);
			        			}
			        		}
			        	}
        			}
        	 	}
        	}
        }
    },
    markRow(channel, grid, adspace, breaktype)
    {
        for ( var d in this.dates) 
        {
            var day = this.dates[d];
        	if ( this.hasAdBreak(channel, grid, adspace, breaktype, day, "markRow"))
        	{
        		if ( this.weekdaySet( this.inputWeekdays, day) )
        		{
					
        			this.mark( channel, grid, this.as( grid, adspace, breaktype, day)[0], breaktype, day);
        		}
        	}
        }
    },

    getInfoObj(channel, date, array, iGrid, adspace, mainAdSpace, key, breaktype, targetGroup)
    {
    	let obj = {};
    	if ( mainAdSpace && date && mainAdSpace.allForecastDetails)
        {
    		//console.log( JSON.stringify(adspace.allForecastDetails))
        	let fc = mainAdSpace.allForecastDetails.find( p=>p.targetgroupId===targetGroup.id && this.weekdaySet( p.weekday, date) > 0);
        	//console.log( JSON.stringify( fc));
    		if ( fc )
        	{
        		obj.grp =  Math.round(fc.grp*10)/10;	
        	}
        }
		
    	if ( this.spotsBreak[ key] )
        {
    		obj.booked = this.spotsBreak[ key];
    	}
		
    	if ( this.options.showUnplaced && this.spotsUnplacedBreak[ key] )
        {
    		obj.unplaced = this.spotsUnplacedBreak[ key];
    	}
		return obj;

    },
    getInfo(channel, date, array, iGrid, adspace, breaktype)
    {
    	if ( iGrid && !this.hasAdBreak( channel, iGrid, adspace, breaktype, date, "getInfo"))
    	{
    		return "";
    	}
        if ( breaktype)
        {
        	let key = this.getKey(channel.id, iGrid, adspace, breaktype, date);
        	
        	if ( this.spotsBreak[ key] )
	        {
        		if ( this.spotsUnplacedBreak[ key])
        		{
        			return this.spotsBreak[ key] + " [" +this.spotsUnplacedBreak[ key]+"]";
        		}
	    		return this.spotsBreak[ key];
	    	}
        	else if ( this.spotsUnplacedBreak[ key] )
	        {
        		return  "[" +this.spotsUnplacedBreak[ key]+"]";
	    	}
			return "";

        }
        else if ( iGrid)
        {
            let key = channel.id+"."+this.isoDates[date]+"."+iGrid.detail.id;
			//console.log( key+ "-->"+this.spotsGrid[ key])
            let n = this.spotsGrid[ key];
            return n > 0 ? n : "";
        	
        }
        else
        {
            let key = channel.id+"."+this.isoDates[date];
	        if ( this.spotsDay[ key] )
	        {
	    		return this.spotsDay[ key];
	    	}
	    }
	    return "";
    },
    markGridAll(channel, grid)
    {
        for ( var d in this.dates) 
        {
            let day = this.dates[d];

    		for ( var iadspace in grid.adspace)
    		{
    			var adspace = grid.adspace[iadspace];
    			let types = this.filter( adspace.breakTypes);
    			for ( var ibType in types)
    			{
    				var bType = types[ibType];
    				if ( this.hasAdBreak(channel, grid, adspace, bType, day, "markGridA"))
		        	{
		        		if ( this.weekdaySet( this.inputWeekdays,day) )
		        		{
		        			this.mark( channel, grid, this.as( grid, adspace, bType, day)[0], bType, day);
		        		}
		        	}
    			}
    	 	}

        	
        }
    },
    markAll(channel)
    {
        for ( var d in this.dates) 
        {
            var day = this.dates[d];
            for ( var slot in this.timeSlots[channel])
            {
            	var timeSlot = this.timeSlots[channel][slot];
            	
            	for ( var igrid in this.gridData[channel][timeSlot])
            	{
            		var grid = this.gridData[channel][timeSlot][igrid];

            		for ( var iadspace in grid.adspace)
            		{
            			var adspace = grid.adspace[iadspace];
            			let types = this.filter( adspace.breakTypes);
            			for ( var ibType in types)
            			{
            				var bType = types[ibType];
            				if ( this.hasAdBreak(channel, grid, adspace, bType, day, "markal"))
				        	{
				        		if ( this.weekdaySet( this.inputWeekdays,day) )
				        		{
				        			this.mark( channel, grid, this.as( grid, adspace, bType, day)[0], bType, day);
				        		}
				        	}
            			}
            	 	}
            	}
            }

        	
        }
    },
    mark( channel, grid, adspace, breaktype, day)
    {
    	let idx = this.getKey(channel, grid, adspace, breaktype, day);
    	
    	if ( this.marked[ idx] )
    	{
    		this.marked[ idx] = false;
    		this.markedSpots--;
    		if ( adspace && day && adspace.allForecastDetails)
            {
        		//console.log( JSON.stringify(adspace.allForecastDetails))
            	let fc = adspace.allForecastDetails.find( p=>p.targetgroupId===this.targetGroup.id && this.weekdaySet( p.weekday, day) > 0);
            	//console.log( JSON.stringify( fc));
        		if ( fc )
            	{
        			this.markedGRP -= Math.round(fc.grp*10)/10;	
            	}
            }
    	}
    	else
    	{
    		//this.marked[ idx] = true;
    		this.$set( this.marked, idx, true);
    		this.markedSpots++;
    		if ( adspace && day && adspace.allForecastDetails)
            {
        		//console.log( JSON.stringify(adspace.allForecastDetails))
            	let fc = adspace.allForecastDetails.find( p=>p.targetgroupId===this.targetGroup.id && this.weekdaySet( p.weekday, day) > 0);
            	//console.log( JSON.stringify( fc));
        		if ( fc )
            	{
        			this.markedGRP += Math.round(fc.grp*10)/10;	
            	}
            }
    	}
    	
    	//this.$forceUpdate();
    },
    getAdBreakClass( channel, grid, adspace, breaktype, day)
    {
    	let idx = this.getKey(channel, grid, adspace, breaktype, day);
    	//console.log("CHECK>> "+ idx + " " +this.marked[ idx]);
    	if ( this.marked[ idx] )
    	{
    	    
    		return "BMadbreakMarked"
    	}
    	return "BMadbreak";
    },
    isMarked( channel, grid, adspace, breaktype, day)
    {
    	let idx = this.getKey(channel, grid, adspace, breaktype, day);
    	//console.log("CHECK>> "+ idx + " " +this.marked[ idx]);
    	return this.marked[ idx];
    },
    markedColor( channel, grid, adspace, breaktype, day)
    {
    	if ( this.isMarked(channel, grid, adspace, breaktype, day))
    	{
    		return "green";
    	}
    	return "transparent";
    },
    getStyleForDayTable()
    {
    	return "float: top left; display: flex; width: "+this.getWidth()+"px !important; overflow-x: auto;"
    },
    getStyle(name, channel, slot, grid, date, adspace, breaktype)
    {
        var w = 30;
        var h = 1;
        if ( grid && name != 'day' )
        {
        	h = this.getNumberOfBreaks( grid.adspace);
        	if ( name != 'full' )
        	{ 
        		h += 2;
        	}
        }
        else if ( channel && (slot === 0 || slot) )
        {
            h = 0;
        	let grids = this.gridData[channel][slot];
        	for ( var g in grids )
        	{
        		h += this.getNumberOfBreaks( grids[g].adspace);
        		h+=2;
        	}
        	
        }
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=50;
    	}
    	if ( name === 'program' )
        {
        	w=70;
    	}
    	//console.log( channel +"/"+name+"/"+grid+"/"+date);
    	if ( breaktype )
    	{
    		let key = this.getKey(channel, grid, adspace, breaktype, date);
    		let g = this.consumedBreak[ key ];
    		if ( g > breaktype.maxAdTimeISOInSeconds )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fcc; color: #444;";
    		}
    		g = this.spotsBreakTotal[ key ];
    		if ( g > breaktype.maxAds )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fbb; color: #444;";
    		}
    	}
    	else if ( channel && date && grid && name == 'day' )
    	{
    		let day = channel+"."+this.isoDates[date];
            let gridKey = day+"."+grid.detail.id;
            //console.log( gridKey);
            let g = this.consumedGrid[ gridKey ];
            //console.log( g);
            let l = g ? g: 0;
            //console.log( "grid: "+ grid.detail.name+ "-> " + grid.adDurationInSec+ " --> " + l); 
            if ( g > grid.adDurationInSec )
            {
    			return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; background-color: #fcc;";
    		}
    	}
    	
    	if ( name == 'full' )
    	{
    		return "height: "+(14*h)+"pt; width: 100% !important;";
    	}
    	return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; color: #000;";
    },
    getStyle4Break(channel, grid, date, adspace, breaktype)
    {
    	if ( breaktype )
    	{
    		let key = this.getKey(channel, grid, adspace, breaktype, date);
    		let g = this.consumedBreak[ key ];
    		if ( g > breaktype.maxAdTimeISOInSeconds )
            {
    			return "background-color: #fcc; color: #444;";
    		}
    		g = this.spotsBreakTotal[ key ];
    		if ( g > breaktype.maxAds )
            {
    			return "background-color: #fbb; color: #444;";
    		}
    	}
    	
    	return "";
    },
    getStyle2(name, channel, slot, grid)
    {
        var w = 30;
        var h = 1;
        if ( grid )
        {
        	h = this.getNumberOfBreaks( grid.adspace);
        }
        else if ( channel && slot )
        {
            h = 0;
        	let grids = this.gridData[channel][slot];
        	for ( var g in grids )
        	{
        		h += this.getNumberOfBreaks( grids[g].adspace);
        	}
        }
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=50;
    	}
    	if ( name === 'program' )
        {
        	w=70;
    	}
    	if ( name == 'full' )
    	{
    		return "height: "+(28*h)+"pt; width: 100% !important";
    	}
    	return "height: "+(28*h)+"pt; width: "+w+"pt !important; valign: bottom !important";
    },
    getNumberOfBreaks(adspace)
    {
        var count = 0;
    	for ( var i in adspace)
    	{
    		let space = adspace[i];
    		let types = this.filter( space.breakTypes);
    		for ( var bt in types)
    		{
    		  let breakType = types[bt];
    		  if ( this.inputBreakTypeIds.includes( breakType.id ))
    		  {
    		  	count++;
    		  }
    		} 
    	}
    	return count;
    },
    getDates()
    {
    	this.dates = new Array();
    	this.isoDates = new Array();
    	this.weekdays = new Array();
    	this.dayHasBreak = new Array();
    	this.weekdayBITS = new Array();
    	var bit = 1;
    	for ( var i = 0; i < 7; i++)
    	{
    		this.weekdayBITS[i] = bit;
    		bit *= 2;
    	}
    	//alert( JSON.stringify( this.weekdayBITS));
    	let d = new Date(this.fromDate);
    	d = new Date( d.getTime() + d.getTimezoneOffset() * -60000 );
    	//alert( this.fromDate+"-->"+d+"-->"+ d.toISOString());
    	let untilDate = new Date(this.untilDate);
    	untilDate.setHours( d.getHours());
    	untilDate.setMinutes( d.getMinutes());
    	untilDate.setSeconds( d.getSeconds());
    	//alert(d);
    	let locale = sessionStorage.locale.replace('_','-');
    	var options = { month: 'numeric', day: 'numeric' };
    	var weekdayLabelARR = [];
      	let wdArr = JSON.parse(sessionStorage.weekdays);
      	for ( var w=1; w < 8; w++ )
      	{
        	let x = wdArr[w];
         	if ( x.length > 2 )
         	{
             	x = x.substring(0,2);
         	}
         	weekdayLabelARR.push( x);    
      	}
      	var lastIso = "";
      	let c =0;
      	//d = new Date( d.getTime() - d.getTimezoneOffset() * -60000 );
    	while ( d <= untilDate)
    	{
    	    c++;
    	    
    	    let dateStr = d.toLocaleString( locale, options);
    		this.dates.push( dateStr );
    		let isoDate = d.toISOString().split('T')[0];
    		this.isoDates[dateStr] = isoDate;
    	    this.weekdays[dateStr] = this.weekdayBITS[d.getDay()];
    	    
    	    this.weekdayLabel[dateStr] = weekdayLabelARR[d.getDay()];
    	    //alert( d.getDay()+"/"+this.weekdayBITS[d.getDay()]+"::"+ this.weekdays[dateStr]+"-"+dateStr);
    		d.setDate(d.getDate()+1);
    	}
    	//alert( d - untilDate);
    	return this.dates;
    },
    dayStyle() {
    	return "float: left; width: 4%; ";
    },
    openEntity( event, entity, id )
     {
        let that = this;
        //console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
    closeEditor()
    {
         this.showEditor = false;
    },
    
	weekdaySet(weekdays, date)
	{
		let wd = this.weekdays[date];
    	return ( weekdays & wd )
	},
    setPlacement(info) { 
       let that = this;
       this.startLoader();
       //console.log("CHECKPT setPlacement()");
       if ( info.placement.id != that.placement.id )
       {
       		this.clearFilter();
       }
       that.placement = info.placement;
       let flt = { media: this.selectedMedia, grids: this.selectedGrids, pg: this.selectedPG, timeslots: this.selectedTimeslots, categs: this.selectedCats };
      
       that.placementFrom = new Date( this.placement.fromDate);
       that.placementTo = new Date( this.placement.untilDate);
       that.inventory = info.inventory;
       that.plcmBreakTypes = new Array();
       that.breakTypeData = info.allBreakTypes;
       for ( var bt in that.breakTypeData )
       {
          let breakType = that.breakTypeData[bt];
          //alert(bt +"-->"+ JSON.stringify(breakType));
          if ( !that.placement.breakTypes || that.placement.breakTypes.length==0 || that.placement.breakTypes.includes( breakType.id) )
          {
          	that.plcmBreakTypes.push( breakType);
          }
       }
       //console.log("CHECKPT 4");
       
	   that.breakTypeData = that.plcmBreakTypes.slice();
	   //console.log(JSON.stringify(that.breakTypeData));
	   that.getAdBreaks(that.placement);       
    },
    printTimeslot( elem)
    {
    	return printTime2(elem.starttime) + " - " + printTime2(elem.starttime+elem.durationInSec);
    },
    doFilterGrid()
    {
		let tmp = [... this.grid];
		var keepFilters = new Array();
		if ( this.selectedMedia && this.selectedMedia.length > 0 )
     	{
     		tmp =  tmp.filter(elem => this.selectedMedia.includes(elem.media.shortname) );
     		keepFilters.push('Media');
     	}
     	if ( this.selectedGrids && this.selectedGrids.length > 0 )
     	{
     		tmp =  tmp.filter(elem => this.selectedGrids.includes(elem.content.name) );
     		keepFilters.push('Grid');
     	}
     	if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
     	{
     		tmp =  tmp.filter(elem => this.selectedTimeslots.includes( this.printTimeslot(elem)) );
     		keepFilters.push('Timeslot');
     	}
     	if ( this.selectedPG && this.selectedPG.length > 0 )
     	{
			for ( let gridi in tmp)
			{
				tmp[ gridi] = {...tmp[ gridi]};
				tmp[ gridi].adspace =  tmp[ gridi].adspace.filter(elem => this.selectedPG.includes(elem.pg) );
			}
     		
     		keepFilters.push('PG');
     	}
     	if ( this.selectedCats && this.selectedCats.length > 0 )
     	{
     		tmp =  tmp.filter(elem => this.selectedCats.includes(elem.category.shortname) );
     		keepFilters.push('Category');
     	}

    	this.getGridData(tmp, keepFilters); 
    	return tmp;
    },
    getGridData( useThisGrid, keepFilters)
    {
    	
        this.mediaSet = new Array();
        this.channels = new Array();
        this.channelDiffs = new Array();
        this.timeSlots = new Array();
        this.gridData = new Array();
        if (!keepFilters.includes("Media"))
        {
        	this.allMedia = new Array();
        }
        if (!keepFilters.includes("Grid"))
        {
        	this.allGrids = new Array();
        }
        if (!keepFilters.includes("PG"))
        {
        	this.allPG = new Array();
        }
        if (!keepFilters.includes("Timeslot"))
        {
        	this.allTimeslots = new Array();
        }
        if (!keepFilters.includes("Category"))
        {
        	this.allCats = new Array();
        }
        let dateFrom = new Date(this.fromDate).toISOString().split('T')[0];
        let dateTo = new Date(this.untilDate).toISOString().split('T')[0];
        let dFrom = new Date(dateFrom);
        let dTo = new Date(dateTo);
    	for ( var grd in useThisGrid )
        {
    		
            let elem = useThisGrid[grd];
            let starttime = elem.starttime;
            let mID = elem.media.id;
            this.channelDiffs[ mID] = elem.diff;
            
            if ( ! this.gridData[mID] )
            {
            	this.gridData[mID] = new Array();
            }
            if ( ! this.timeSlots[mID] )
            {
            	this.timeSlots[mID] = new Array();
            }
            if ( ! this.gridData[mID][starttime] )
            {
            	this.gridData[mID][starttime] = new Array();
            }
            elem.gridId = elem.detail.id;
        	this.gridData[mID][starttime].push(elem);
			
        	if ( elem.replacements && elem.replacements.length )
        	{
        		for ( let repl in elem.replacements )
        		{
        			let replace = elem.replacements[repl];
        			let check = new Date(replace.date);
        			//alert(replace.date+":"+dateFrom+":"+dateTo+(!(check < dFrom || check > dTo))) 
        			if (!(check < dFrom || check > dTo) )
        			{
            			
            			let dupl = {...elem}
            			dupl.detail = {...elem.detail}
            			delete dupl.replacements;
            			
            			
            			//console.log( dupl.detail.name)
            			dupl.gridId = elem.detail.id+"R"+replace.id;
            			dupl.replacing = true;
            			dupl.detail.name = replace.name;
            			dupl.detail.fromDate = check;
            			dupl.detail.untilDate = check;
            			//dupl.detail.pricegroup = 99; //xxxxx
            			this.gridData[mID][starttime].push(dupl);
        			}
        		}
        	}
        	else
        	{
        		delete elem.replacements;
        	}
			
        	this.channels[mID] = elem.media;
        	let slots = this.timeSlots[mID].length;
        	//alert(slots);
        	if ( slots == 0 || this.timeSlots[mID][slots-1] !== starttime)
        	{
        		this.timeSlots[mID].push(starttime);
        	}
        	//console.log(JSON.stringify(elem.media));
        	if (!keepFilters.includes("Media"))
        	{	
	        	if ( !this.allMedia.includes(elem.media.shortname))
	            {
	            	this.allMedia.push(elem.media.shortname);
	            }
	        }
	        if (!keepFilters.includes("Timeslot"))
        	{
        		var ts = this.printTimeslot( elem);
	            if ( !this.allTimeslots.includes(ts))
	            {
	            	this.allTimeslots.push(ts);
	            }
	        }
	        if (!keepFilters.includes("Grid"))
        	{
	            if ( !this.allGrids.includes(elem.content.name))
	            {
	            	this.allGrids.push(elem.content.name);
	            }
	        }
	        if (!keepFilters.includes("Category"))
        	{
	            if ( !this.allCats.includes(elem.category.shortname))
	            {
	            	this.allCats.push(elem.category.shortname);
	            }
	        }
            if (!keepFilters.includes("PG"))
        	{
            	if ( !this.allPG.includes(elem.detail.pricegroup))
            	{
            		this.allPG.push(parseInt(elem.detail.pricegroup));
            	}
            }
        	if ( this.mediaSet.length == 0 || this.mediaSet[this.mediaSet.length-1] != mID)
        	{
        		this.mediaSet.push(mID);
        	}
        }
        this.allPG.sort( (a, b) => a - b);
        
    },
    getAdBreaks(plcm) { 
       this.startLoader();
       this.getDates();
       this.placements = [];
       this.marked = new Array();
       this.markedSpots = 0;
       this.markedGRP = 0;
       this.showPlacementGrid = true;
       this.doFilterGrid();     
    },
    clearFilter() { 
       this.startLoader();
       this.selectedMedia = new Array();
       this.selectedGrids = new Array();
       this.selectedPG = new Array();
       this.selectedTimeslots = new Array();
       this.selectedCats = new Array();
       this.selectedSpotReasons = new Array();
       this.doFilterGrid();  
    },

   printTime(time)
   {
       		return printTimeHHMM(time);
   },
   printTime2(time)
      {
        return printTime2(time);
   },
   startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	
  },
  computed: {
  	spotsSelected: { 
  	    get() {
	        var spots = new Array()
	    	for ( var x in this.marked )
	    	{
	    		if ( this.marked[x] )
	    		{
	    			spots.push( x );
	    		}
	    	}
	    	return spots;
      }
  	}
  },
  watch: {
    time: function() {
        if ( this.placementInfo )
        {
    		this.setPlacement( this.placementInfo);
    	}
    },
    markBreaks: function()
    	{
    		this.marked = [];
    		this.markedSpots = 0;
    		//this.markedGRP = 0;
    		for ( let x in this.markBreaks)
    		{
    			this.$set( this.marked, this.markBreaks[x], true);
        		this.markedSpots++;
    		}
    	},
    
    user: function() {
    	if ( this.user.fontSize )
        {
        	this.fontSize = this.user.fontSize;
        }
    },
    spotsPlacementTotal: function() {
    	this.markedSpots = this.marked.length;
    },
    invalidSpots: function() {
	    //console.log("BOGR.watch(invalidSpots:"+this.spots.invalidSpots+")")
	}
  },
  
  updated() {
  //console.log("update...");
  this.$nextTick(function () {
    // Code that will run only after the
    // entire view has been re-rendered
    //console.log("stopping loader");
    if (this.loadingActive)
    {
        //console.log("stop loader: " + timers.length)
	    
	    timers.push( setTimeout( this.stopLoader, 100));
    }
  })
  }
}
</script>
<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.xtransition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
}  
.xtransitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.BMday {
  display: table-cell;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.dontWrap90 {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 50%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}
.BMadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #888;
  background-color: #fff; 
  text-align: center;
  height: 100%;
  margin: 0pt;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
  position: relative; 
  height: 14pt; 
  width: 30pt !important; 
}
.BMnoadbreak {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #bbb;
  background-color: #eee;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMnoadbreakExcl {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #bbb;
  background-color: #fcc;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMadbreak1 {
	display: inline-block; 
	width: 66%;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 2pt;
   
    margin: 0pt;
    height: 100%;
    margin: 0pt;
}
.BMadbreak2 {
	display: inline-block; 
	width: 28% !important; 
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
	height: 100%;
	margin: 0pt;
    padding: 0pt;
}
.BMadbreakMarked {
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  color: #f0c;
  background-color: #bcc; 
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.BMbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
  cursor: pointer;
}
.BMadbreak:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMadbreakMarked:hover {
  color: #fff !important;
  background-color: #da58da !important; 
}
.BMheader { 
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 8pt; 
  font-weight: bold;
  
  //overflow-x: hidden;
  //overflow-y: hidden;
 
  white-space: nowrap; /* Don't forget this one */ 
  //text-overflow: ellipsis;  
  
  //border-bottom: 1pt solid #ccc;
  background-color: #eef; 
 }
.BMheaderInv {
	color: transparent;
}
.BMprogram {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
	//border: 1pt solid #fcc; 
	position:relative;
	z-index: 0;
}
.BMprogram:hover {
	color: #fff !important; 
    background-color: #888 !important; 
	cursor: pointer;
	
}
.puInfo { padding-left: 2em; z-index: 999; }
.BMtime {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMuserTime {
	color: #888;
	padding-left: 2pt;
}
.BMinfo {
	background-color: #58dada; 
}
.BMbreak {
	background-color: #58dada;
	border-bottom: 1pt solid grey; 
}
.BMheadLine {
  //color: #fff !important;
  background-color: #58dada !important; 
}
.todrag {
 display: block;
 width: 100%; 
 height: 100%;
}
.BMheaderDay { 
  font-size: 7pt; 
  display: table-cell;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
  justify-content: center;  
  border-left: .5pt solid #aaa; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
  overflow-x: hidden;
  color: #000;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .BMHeadActions:hover {
 	cursor: pointer;
 	color: #fff !important; 
    background-color: #888 !important; 
 }
 .BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
 .colored {
  background-color: #eef; 
 }
 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
}  
</style>
